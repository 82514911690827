import React, {useState} from 'react';
import ReportResult from "./ReportResult";
import {REPOST_SUMM_ROUND, copyClipboard, formatDate, formatPrice, REPORT_TABLE_ITOG_TEXT} from "../../Constants";
import {Button, TextField} from "@material-ui/core";
import {FileCopyRounded as Copy} from '@material-ui/icons';

const ItogSmena = props => {
    const {
        data = null,
        tableFields, formFields,
        //сортировки
        sort, sortBy,
        dates,
    } = props;

    const [guests, setGuests] = useState(0);
    const [isCopy, setIsCopy] = useState(false);

    // console.clear();
    // console.log('ItogSmena');
    // console.dir(data);
    let {
        bar = [],
        barTotalItems = 0,
        itogBar = null,
        food = [],
        foodTotalItems = 0,
        itogFood = null,
    } = data;


    // return (
    //     <TextField
    //         variant="outlined"
    //         size={'small'}
    //         value={guests}
    //         InputLabelProps={{
    //             shrink: true,
    //         }}
    //         onChange={(e) => {
    //             console.clear();
    //             let v = e.target.value;
    //             let vInit = v;
    //
    //             v = v.trim();
    //             if (v == '')
    //                 v = 0;
    //
    //             v = parseInt(v);
    //             console.log(vInit, v);
    //             setGuests(v);
    //         }}
    //     />
    // );


    if (itogBar == null || itogFood == null)
        return null;


    let content = [];

    let avgSumBar = 0,
        avgSumFood = 0;
    if (barTotalItems) {

        // console.dir(itogBar);
        avgSumBar = itogBar.sum_with_discount / barTotalItems;


        content.push(<h2 key={'is-h-bar'}>Бар</h2>);
        content.push(<div key={'is-tbl-bar'}>
            <ReportResult
                tableFields={tableFields}
                formFields={formFields}
                data={bar}
                reportType={''}
                sort={sort}
                sortBy={sortBy}
                showCounter={true}
            />
            <div className={"itog-smena-ftr-total is-total"}>
                <div>Средняя бар:</div>
                {formatPrice(avgSumBar)}  р.
                {/*{avgSumBar.toFixed(REPOST_SUMM_ROUND)} р.*/}
            </div>
        </div>);
    }
    if (foodTotalItems) {
        avgSumFood = itogFood.sum_with_discount / foodTotalItems;
        content.push(<h2 key={'is-h-food'}>Еда</h2>);
        content.push(<div key={'is-tbl-food'}>
            <ReportResult
                tableFields={tableFields}
                formFields={formFields}
                data={food}
                reportType={''}
                sort={sort}
                sortBy={sortBy}
                showCounter={true}
            />
            <div className={"itog-smena-ftr-total is-total"}>
                <div>Средняя еда:</div>
                {formatPrice(avgSumFood)} р.
            </div>
        </div>);
    }


    let contentTotal = [];

    let sum_with_discount_total = (itogBar.sum_with_discount + itogFood.sum_with_discount);
    let sum_deposit_total = (itogBar.sum_deposit + itogFood.sum_deposit);

    contentTotal.push(<div className={"is-total"} key={'is-total1'}>
        <div>Общая бар+еда:</div>
        {formatPrice(sum_with_discount_total)} р.
        {" "}
        из них депозит
        {" "}
        {formatPrice(sum_deposit_total)} р.
        {/*{sum_deposit_total.toFixed(REPOST_SUMM_ROUND)} р.*/}
    </div>);

    let avgBarAndFood = sum_with_discount_total / (barTotalItems + foodTotalItems);

    contentTotal.push(<div className={"is-total"} key={'is-total2'}>
        <div>Средняя бар+еда:</div>
        {formatPrice(avgBarAndFood)} р.
        {/*{avgBarAndFood.toFixed(REPOST_SUMM_ROUND)} р.*/}
    </div>);

    let totalCheques = itogBar.cheques + itogFood.cheques;
    contentTotal.push(<div className={"is-total"} key={'is-total3'}>
        <div>Чеков:</div>
        {totalCheques}
    </div>);
    contentTotal.push(<div className={"is-total"} key={'is-total4'}>
        <div>Гостей:</div>

        <TextField
            variant="outlined"
            size={'small'}
            value={guests}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={(e) => {
                console.clear();
                let v = e.target.value;
                let vInit = v;

                v = v.trim();
                if (v == '')
                    v = 0;

                v = parseInt(v);
                // console.log(vInit, v);
                setGuests(v);
            }}
        />
    </div>);


    let avgCheque = guests > 0 ? sum_with_discount_total / guests : 0;
    contentTotal.push(<div className={"is-total"} key={'is-total5'}>
        <div>Средний чек:</div>
        {formatPrice(avgCheque)} р.
        {/*{avgCheque.toFixed(REPOST_SUMM_ROUND)} р.*/}
    </div>);

    let chequeGuest = guests > 0 ? totalCheques / guests : 0;
    contentTotal.push(<div className={"is-total"} key={'is-total66'}>
        <div>Чек/Гость:</div>

        {chequeGuest.toFixed(REPOST_SUMM_ROUND)}
    </div>);

    const copy2clipboard = () => {
        console.log('copy2clipboard', dates);

        let num = 0;
        let txt = `Выручка ${formatDate(dates.dateStart)}`;
        if (dates.dateStart != dates.dateEnd)
            txt += ` - ${formatDate(dates.dateEnd)}`;
        txt += `\n\n`;

        txt += `№ Группа Сумма со скидкой, р. Чеков Сумма депозитов, р.\n\n`;

        console.log(bar);
        if (barTotalItems) {
            bar.map(item => {
                if (item.group != REPORT_TABLE_ITOG_TEXT) {
                    txt += `${++num}. ${item.group} ${formatPrice(item.sum_with_discount)} (${item.cheques}) депозит ${formatPrice(item.sum_deposit)} \n`;
                }
            });
            txt += `\nОбщая бар: ${formatPrice(itogBar.sum_with_discount)} из них депозит: ${formatPrice(itogBar.sum_deposit)}`;
            txt += `\nСредняя бар: ${formatPrice(avgSumBar)}`;
        }
        num = 0;
        if (foodTotalItems) {
            if (barTotalItems)
                txt += `\n\n`;
            food.map(item => {
                if (item.group != REPORT_TABLE_ITOG_TEXT) {
                    txt += `${++num}. ${item.group} ${formatPrice(item.sum_with_discount)} (${item.cheques}) депозит ${formatPrice(item.sum_deposit)} \n`;
                }
            });
            txt += `\nОбщая еда: ${formatPrice(itogFood.sum_with_discount)} из них депозит ${formatPrice(itogFood.sum_deposit)}`;
            txt += `\nСредняя еда: ${formatPrice(avgSumFood)}`;
        }
        txt += `\n\nОбщая бар + еда: ${formatPrice(sum_with_discount_total)} из них депозит: ${formatPrice(sum_deposit_total)}`;
        txt += `\nОстаток на картах: `;
        txt += `\n`;

        txt += `\nЧеков: ${totalCheques}`;
        txt += `\nГостей: ${guests}`;
        txt += `\nСредний чек: ${formatPrice(avgCheque)}`;
        txt += `\nЧек/гость: ${chequeGuest.toFixed(REPOST_SUMM_ROUND)}`;

        copyClipboard(txt);
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000);
    };

    return (
        <div className={"itog-smena"}>
            {content}
            <div className={"is-total-wrap"}>
                {contentTotal}
            </div>

            <div className={"is-copy"}>
                <Button
                    variant="outlined"
                    startIcon={<Copy/>}
                    onClick={copy2clipboard}
                >
                    Копировать текст в буфер обмена
                </Button>
                {
                    isCopy
                        ? <span>скопировано</span>
                        : null
                }
            </div>

        </div>
    )

};

export default ItogSmena;