import React, {useState, useEffect} from 'react';
import {Grid, Paper, Switch, FormControlLabel} from "@material-ui/core";
import axios from "axios";
import {DEBUG, API_URL} from "../Constants";

/*
 * mklink /H  ruin_api.php d:\react_projects\iiko_report\src\api.php
 *
 */

const options = {
    responseType: "json",
    timeout: 30000,
    headers: {
        // "Content-Type": "application/x-www-form-urlencoded"
        // "Content-Type": "multipart/form-data"
        // "Access-Control-Allow-Origin": "*"
    }
}

const Settings = ({restsList, settingsInit = {}}) => {
    // console.dir(restsList);

    console.dir(settingsInit);

    const [settings, setSettings] = useState(settingsInit);

    //
    // const getData = async () => {
    //     const {data} = await axios.post(
    //         API_URL, {
    //             action: 'getSettings',
    //         }, options);
    //
    //     console.dir(data);
    //
    //     if (data.error != '')
    //         alert(data.error);
    //
    //     if (data.settings)
    //         setSettings(data.settings);
    //
    // }

    const saveSettings = async (restId, key, value) => {

        let settingsNew = {...settings};

        if (!settingsNew[restId])
            settingsNew[restId] = {};

        settingsNew[restId][key] = value;

        const {data} = await axios.post(
            API_URL, {
                action: 'setSettings',
                settings: settingsNew
            });

        if (data.error != '')
            alert(data.error);
        else {
            // console.dir(settingsNew);
            setSettings(settingsNew);
        }



    }

    useEffect(() => {
        // getData().then(null);
        setSettings(settingsInit);
    }, [settingsInit]);


    return (
        <>
            <h2>Настройки ресторанов</h2>
            <Grid container spacing={1}>
                {
                    restsList.map(rest => {

                        let isFood = (settings[rest.id] != undefined  && settings[rest.id]['isFood'] != undefined && settings[rest.id]['isFood']);
                        // console.log(rest.name, isFood);
                        return (
                            <Grid item xs={4} key={rest.id}>
                                <Paper style={{padding: '10px 20px'}}>
                                    <h3>{rest.name}</h3>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={isFood}
                                                value={isFood}
                                                onChange={async (e) => {
                                                    await saveSettings(rest.id, 'isFood', e.target.checked)
                                                }}
                                                name="isFood"
                                                color="primary"
                                            />
                                        }
                                        label="Еда"
                                    />


                                </Paper>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>
    )

};

export default Settings;
