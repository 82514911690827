import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from "@material-ui/core";
import {EMAIL, SITE_URL} from "../Constants";
import {APP_VERSION} from "../App";
const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: '2rem'
	}
}));
const Header = () => {
	const classes = useStyles();

	return (
		<Grid
			container
			alignItems="center"
			justifyContent={"space-between"}
			// justify="space-between"
			className={classes.header}
		>
			<Grid item>
				<img src="images/logo_pbo.png" />
			</Grid>
			<Grid item>
				<img src="images/logo_its.png" title={`version: ${APP_VERSION}`} />
			</Grid>
			<Grid item>
				<p><strong>ТЕХПОДДЕРЖКА 24/7</strong></p>
				<p>8(800)555-90-84</p>
				<p><a href={"mailto:" + EMAIL}>{EMAIL}</a></p>
				<p><a href={SITE_URL} target="_blank">{SITE_URL}</a></p>
			</Grid>
		</Grid>
	);
};

export default Header;
