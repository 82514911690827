import React, {useState} from 'react';
import {Grid, makeStyles, TextField} from "@material-ui/core";
import RestListCheckAll from "./RestListCheckAll";
import RestListItem from "./RestListItem";
import {ALL_RESTS_SELECTED, in_array, restsOnlineLength, STORAGE_RESTS_SELECTED_IDS} from "../../Constants";
import Storage from "../../Storage";
import FieldsItem from "./FieldsItem";
import Button from "@material-ui/core/Button";
/**
 * Общие данные формы для всех отчетов
 * @param props
 * @returns {*}
 * @constructor
 */
const useStyles = makeStyles(theme => ({
	datesBox: {
		margin: '1rem 0'
	},
	tableNamesBox: {

	},
	buttonWrap: {
		textAlign: 'center',
		marginTop: '2rem'
	}
}));
const ReportForm = (props) => {
	const classes = useStyles();

	const {restsList,
		setFormRestsId, /*Задаем ИД выбранных рестиков*/
		formDates, setFormDates,/* задаем даты */
		tableFields, formFields, setFormFields, /*поля*/
		submitButtonDisabled, handleSubmit,
		//фильтры  21/05/22
		tableFieldsFilters,
		filterSelected, setFilterSelected
	} = props;


	const [allRestsSelected, setAllRestsSelected] = useState(ALL_RESTS_SELECTED);

	// console.log('__________ReportForm - INIT -- allRestsSelected=' + allRestsSelected);
	// console.log('submit disabled ' + submitButtonDisabled);
	// console.log(tableFields);
	// console.log(tableFieldsFilters);
	const storageRestsSelectedId = Storage.get(STORAGE_RESTS_SELECTED_IDS, [], true);
	// console.log('storageRestsSelectedId on INIT: ');
	// console.log(storageRestsSelectedId);

	let restsDef = [];
	let selectedLength = 0;
	restsList.forEach(rest => {
		// console.log('restsList.forEach');
		// console.log(rest);
		if (rest.online) {
			if (storageRestsSelectedId.length)
				rest.selected = in_array(rest.id, storageRestsSelectedId);
			else
				rest.selected = allRestsSelected;
		}
		else
			rest.selected = false;
		restsDef.push(rest);

		if (rest.selected)
			selectedLength++;
	});

	const [rests, setRests] = useState(restsDef);
	if (allRestsSelected && selectedLength !== restsOnlineLength(restsList))
		setAllRestsSelected(false);

	// console.log(restsDef);
	// console.log(rests);

	const onSelectRest = (e) => {
		const tmp = [...rests];
		const selectedId = [];
		// console.clear();
		// console.log(e.target.id + '; checked=' + e.target.checked);
		tmp.forEach((rest, i) => {
			if (rest.online) {
				if (e.target.id === 'all') {
					tmp[i].selected = e.target.checked;
				} else {
					if (rest.id == e.target.id)
						tmp[i].selected = e.target.checked;
				}
			}
			if (tmp[i].selected)
				selectedId.push(rest.id);

		});
		// console.log('selected/online: '+selectedId.length + '/' + restsOnlineLength(restsList));

		//установить для галки выделить все
		setAllRestsSelected(selectedId.length === restsOnlineLength(restsList));

		Storage.set(STORAGE_RESTS_SELECTED_IDS, selectedId);
		setRests(tmp);

		// console.log('after-checked');
		// console.log(rests[0]);

		setFormRestsId(selectedId);
	};
	// let restsListHtml = [];
	//
	// rests.forEach(rest => {
	// 	restsListHtml.push(<RestListItem
	// 		key={rest.id}
	// 		// isSelected={allSelected}
	// 		// handleSelectedRests={handleSelectedRests}
	// 		onSelectRest={onSelectRest}
	// 		data={rest}
	// 	/>);
	// });

	const handleDates = (e) => {
		console.log('id: ' + e.target.id + '; ' + e.target.value);
		const tmp = {...formDates};

		tmp[e.target.id] = e.target.value;

		setFormDates(tmp);
	};
	const handleSelectedFields = (name, isChecked) => {
		// console.log('FIELD ' + name + '=' + isChecked);
		// console.log(formFields);
		const fields = [...formFields];

		if (isChecked && !in_array(name, fields))
			fields.push(name);
		else if (!isChecked && in_array(name, fields))
			fields.splice(fields.indexOf(name), 1)

		setFormFields(fields);
	};

	let tableFieldsHtml = [];
	if (tableFields.length) {
		tableFields.forEach((field,i) => {
			if (field.canOff)
				tableFieldsHtml.push(
					<FieldsItem
						key={i}
						{...field}
						handleSelectedFields={handleSelectedFields}
						filter={(
							field.name in tableFieldsFilters !== false
								? tableFieldsFilters[field.name]
								: null
						)}
						filterSelected={filterSelected}
						setFilterSelected={setFilterSelected}
					/>
				);
		});
	}


	return (
		<>
		<RestListCheckAll
			allRestsSelected={allRestsSelected}
			setAllRestsSelected={setAllRestsSelected}
			onSelectRest={onSelectRest}
		/>
			<Grid container
			alignItems="center"
			// justify="space-around"
			//   className={classes.restsList}
			>
				{/*{restsListHtml}*/}
				{rests.map((rest, i) => {
					return (
						<RestListItem
							key={i}
							onSelectRest={onSelectRest}
							data={rest}
						/>
					)
				})}

				<Grid container
					  spacing={2}
					  className={classes.datesBox}
				>
					<Grid item xs={12} sm={3} >
						Период
					</Grid>
					<Grid item xs={12} sm={9}>
						с
						<TextField
							id="dateStart"
							label=""
							type="date"
							defaultValue={formDates.dateStart}
							// className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={handleDates}
						/>
						&nbsp; по &nbsp;
						<TextField
							id="dateEnd"
							label=""
							type="date"
							defaultValue={formDates.dateEnd}
							// className={classes.textField}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={handleDates}
						/>
					</Grid>
				</Grid>


				<Grid container
					  spacing={2}
					  alignItems={"center"}
				>
					<Grid item xs={12} sm={3}>
						Данные отчета:
					</Grid>
					<Grid item xs={12} sm={9}>
						<Grid container alignItems={"center"}>
							{tableFieldsHtml}
						</Grid>
					</Grid>
				</Grid>
				<Grid container
					  spacing={2}
					// className={classes.tableNamesBox}
				>
					<Grid item xs={12} className={classes.buttonWrap}>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							onClick={() => handleSubmit()}
							disabled={submitButtonDisabled}
						>
							Сформировать Отчет
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
export default ReportForm;
