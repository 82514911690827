import React, {useEffect, useState} from 'react';
import './App.css';
import Login from './components/Login';
import Content from './components/Content';
import CssBaseline from '@material-ui/core/CssBaseline';
import {useCookies} from 'react-cookie';
import {DEBUG, COOKIE_NAME, REFRESH_RESTS_LIST} from "./Constants";
import Api from "./Api";
import {CircularProgress} from "@material-ui/core";
import Storage from "./Storage";


export const APP_VERSION = '26.09.23';


/**
 * http://ruin.itsgroup.ru/
 *
 * use node 16.14.0
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {
	const [cookies] = useCookies([COOKIE_NAME]);
	const [user, setUser] = useState(cookies.user || {});
	const [showLogin, setShowLogin] = useState(true);

	/**
	 * Получаем данные о ресторанах
	 * @type {Array}
	 *
	 */
	const getRestList = async () => {
		// console.log('getRestList');
		Api.defaults.timeout = 30000;

		// setRestsList(TEST_RESTS);
		// return;
		const cacheRestsList = Storage.get('restsList', null, true);

		if (cacheRestsList) {
			setRestsList(cacheRestsList);
			return;
		}


		try {
			const res = await Api.get('get_rests');

			if (res.data.ErrCode === 0 && res.data.Rests !== undefined) {

				if (res.data.Rests.length) {
					setRestsList(res.data.Rests);

					// console.dir(res.data.Rests);

					Storage.set('restsList', res.data.Rests, (DEBUG ? 60 * 30 : 30));
				}

			}
			// else
			// 	alert(res.data.ErrMessage);
		} catch (err) {
			console.log('timout exceed');
			// alert('Превышено время ожидания ответа сервера');
		}

	};

	const [restsList, setRestsList] = useState([]);
	let content = null;
	if (!showLogin) {
		content = <div className={"main-loader"}>
			<p>Загружаем рестораны....</p>
			<CircularProgress/>
		</div>;
		if (!restsList.length)
			getRestList().then(null);
		else
			content = (
				<>
					<Content
						user={user}
						setUser={setUser}
						restsList={restsList}
					/>
					{/*<footer>*/}
					{/*	{APP_VERSION}*/}
					{/*</footer>*/}
				</>
			);
	}

	setTimeout(async () => {
		await getRestList();
	}, REFRESH_RESTS_LIST);

	useEffect(() => {

	}, [restsList]);

	useEffect(() => {
		setShowLogin(!Object.keys(user).length);

	}, [user]);


	return (
		<div className="main-wrap">
			<CssBaseline/>
			{showLogin
				? <Login setUser={setUser}/>
				: content
			}

		</div>
	);

};

export default App;
