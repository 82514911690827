import React, {useEffect, useState} from 'react';
import {makeStyles, Grid, Checkbox, FormControlLabel} from "@material-ui/core";

const RestListCheckAll = (props) => {
	const {allRestsSelected, setAllRestsSelected, onSelectRest} = props;
	// const [isSelected, setSelfSelected] = useState(allRestsSelected);

	// console.log('RestListCheckAll: ' + allRestsSelected);
	return(
		<div >
			<FormControlLabel
				control={<Checkbox
					value={true}
					color="primary"
					checked={allRestsSelected}
					onChange={(e) => {
						setAllRestsSelected(e.target.checked);
						onSelectRest({
							target: {
								id: 'all',
								checked: e.target.checked
							}
						});
						// setSelfSelected(e.target.checked)
					}}
				/>}
				label={allRestsSelected ? "Снять все" : "Выбрать все"}
			/>
		</div>
	);
};

export default RestListCheckAll;