import React, {useEffect, useState} from 'react';
import AppBar from "@material-ui/core/AppBar";
import {makeStyles, Button, Box, Container, Tabs, Tab, Typography, CircularProgress} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {useCookies} from "react-cookie";
import {
    DEBUG,
    API_URL,
    COOKIE_NAME,
    ALL_RESTS_SELECTED,
    REPORT_TABS,
    REPORTS,
    getReportById,
    REPORT_TYPE_DEFAULT,
    // REPORT_TYPE_ITOG_SMENA,
    // REPORT_TAB_DEFAULT,
    // TEST_REPORT_RESULT,
    in_array,
    // COOKIE_MAX_AGE,
    STORAGE_RESTS_SELECTED_IDS
} from "../Constants";
import Header from "../components/Header";
import ReportForm from "../components/report/ReportForm";
import ReportResult from "../components/report/ReportResult";
import Api from "../Api";
import Storage from "../Storage";
import Connections from "./Connections";
import Settings from "./Settings";
import ItogSmena from "./report/ItogSmena";
import SaveToFile from "./report/SaveToFile";
import axios from "axios";


const useStyles = makeStyles(theme => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,

    },
    toolbar: {
        justifyContent: 'space-between'
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    userName: {
        marginRight: '1rem',
    },
    content: {},
    resultLoader: {
        textAlign: 'center',
        marginTop: '2rem'
    }
}));
const TabPanel = (props) => {


    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
};

const Content = (props) => {
    const classes = useStyles();
    const {user, setUser, restsList} = props;

    const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);

    const [settings, setSettings] = useState({});

    const logout = () => {
        removeCookie(COOKIE_NAME);
        setUser({});
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = useState(
        Storage.get('userSelectedTab', REPORT_TYPE_DEFAULT)
    );

    const handleTabs = (e, val) => {
        Storage.set('userSelectedTab', val);
        setReportResult([]);
        setResultTableData([]);
        setTabValue(val);
        setFilterSelected({});
        handleTableFields(val);

    };

    // console.clear();

    /**
     * Изменение данных формы
     * @param key
     * @param val
     */
    const getFullDayMoth = (val) => {
        return val > 9 ? val : '0' + val;
    };

    // const nowDate = DEBUG
    // 	? new Date('2023-08-20')
    // 	: new Date();

    const nowDate = new Date();
    // nowDate.setDate(nowDate.getHours())
    const nowDateFormatted = nowDate.getFullYear() + '-' + getFullDayMoth((nowDate.getMonth() + 1)) + '-' + getFullDayMoth(nowDate.getDate());

    let oneWeekAgo = nowDate;
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    // if (!DEBUG)
    // 	oneWeekAgo.setDate(oneWeekAgo.getDate() - (DEBUG ? 29 : 7));


    const weekAgoFormatted = oneWeekAgo.getFullYear() + '-' + getFullDayMoth((oneWeekAgo.getMonth() + 1)) + '-' + getFullDayMoth(oneWeekAgo.getDate());

    // console.log(nowDate);


    const [formDates, setFormDates] = useState({
        dateStart: weekAgoFormatted,
        dateEnd: nowDateFormatted,
        // dateStartDate: oneWeekAgo,
        // dateEndDate: nowDate
    });
    // const [reportFields, setReportFields] = useState([]);

    let defTableFields = [];
    let defSortField = {field: '', asc: true};
    // console.log(tabValue);
    REPORTS.map((rep) => {
        // console.log(rep.id, tabValue, rep.id == tabValue);
        if (rep.id == tabValue) {
            defTableFields = rep.table;

            if (typeof rep.defSort != 'undefined')
                defSortField = rep.defSort;
        }
    });
    // console.dir(defSortField);
    const [tableFields, setTableFields] = useState(defTableFields);
    const [resultTableFields, setResultTableFields] = useState(defTableFields);
    //фильтры для полей таблиц, чтобы выводить только выбранные в полях значения
    const [tableFieldsFilters, setTableFieldsFilters] = useState({});
    const [filterSelected, setFilterSelected] = useState({});

    const [sort, setSort] = useState(defSortField);

    const handleTableFields = (tab_value) => {
        console.log('handleTableFields', tab_value);
        REPORTS.map((rep) => {
            if (rep.id == tab_value) {

                setTableFields(rep.table);

                let resTblFields = [];
                rep.table.map(fld => {
                    if (fld.canOff)
                        resTblFields.push(fld.name);
                });
                setFormFields(resTblFields);
                // setResultTableFields(resTblFields);

                if (typeof rep.defSort != 'undefined')
                    setSort(rep.defSort);
            }
        });
    };
    let defFormFields = [];
    defTableFields.map(field => {
        if (field.canOff && (field.checked == undefined || field.checked === true)) {
            defFormFields.push(field.name);
        }
    });
    // console.log(defFormFields);
    //показывать поля
    const [formFields, setFormFields] = useState(defFormFields);


    const sortBy = (field) => {

        let newSort = {field: field, asc: !sort.asc};
        console.dir('sortBy', field, sort, newSort);
        // Storage.set('', newSort);
        setSort(newSort);
    }


    const updateResultTableFields = () => {
        const tmp = [];

        tableFields.map(field => {
            if (field.canOff) {
                if (in_array(field.name, formFields))
                    tmp.push(field);
            } else
                tmp.push(field);

        });

        setResultTableFields(tmp);
    };


    const storageRestsSelectedId = Storage.get(STORAGE_RESTS_SELECTED_IDS, [], true);
    let selectedRestsId = [];
    if (ALL_RESTS_SELECTED)
        restsList.forEach(rest => {
            // selectedRestsId.push(rest.id);
            if (rest.online) {
                if (storageRestsSelectedId.length > 0) {
                    if (in_array(rest.id, storageRestsSelectedId))
                        selectedRestsId.push(rest.id);
                } else
                    selectedRestsId.push(rest.id);
            }
        });
    const [formRestsId, setFormRestsId] = useState(selectedRestsId);
    const [resultTableData, setResultTableData] = useState([]);
    /*
     * Подгружаем сортировщик данных для вывода в таблице
     */
    let Sorter = null;
    (async () => {
        if (in_array(tabValue, REPORT_TABS))
            Sorter = await import('./report/sorter/' + tabValue);
    })();

    const filterData = (data) => {
        // console.log('%c filterData > filterSelected', 'color: blue');
        // console.dir(tableFieldsFilters);

        // console.dir(Object.keys(filterSelected));

        if (Object.keys(filterSelected).length == 0)
            return data;

        // console.log('------> do filter');
        // console.dir(data);
        const newData = [];
        data.map((item) => {
            let isAddItem = true;
            for (let itemField in filterSelected) {
                // console.log(itemField, filterSelected[itemField]);
                // console.dir(item);
                // console.log(itemField in item, filterSelected[itemField].includes(item[itemField]), );
                if (itemField in item === false || !filterSelected[itemField].includes(item[itemField]))
                    isAddItem = false;

            }

            if (isAddItem)
                newData.push(item);
        });

        return newData;
    }

    const doSetTableResult = (Sorter, report = null) => {
        // console.clear();

        let _data = report !== null ? report : reportResult;

        if (!_data.length)
            return;
        console.log('doSetTableResult', _data.length);

        let sortField = sort.field;
        // console.dir('SORT BY', sort);

        if (sortField != '') {
            // console.log('sortField=', sortField, 'ASC=', sort.asc);
            _data.sort((a, b) => {
                // console.log(a[sort.field], b[sort.field]);
                let fieldA = a[sortField];
                let fieldB = b[sortField];

                // if (sortField == 'hour') {
                //     return 0;
                //     // fieldA = parseInt(fieldA);
                //     // fieldB = parseInt(fieldB);
                // }

                if (fieldA > fieldB)
                    return sort.asc ? 1 : -1;
                else if (fieldA < fieldB)
                    return sort.asc ? -1 : 1;

                return 0;
            });

        }

        console.log('doSetTableResult after sort', _data.length);
        // console.dir(formFields);

        if (Sorter !== null) {
            let afterFilter = filterData(_data);
            let afterSort = Sorter.sort(
                afterFilter,
                formFields,
                sort
            );

            // console.log(`afterFilter=${afterFilter.length}, afterSort = ${afterSort.length}`);

            setResultTableData(afterSort);
        } else
            setResultTableData(report);
    };

    const getSettings = async () => {
        const {data} = await axios.post(
            API_URL, {
                action: 'getSettings',
            });

        console.dir(data);

        if (data.error != '')
            alert(data.error);

        else if (data.settings)
            setSettings(data.settings);

    }

    useEffect(() => {
        // console.log('useEffect | report FORM datas ------- fro report: ' + tabValue);
        // console.log(filterSelected);
        // console.log('| formRestsId: ' + formRestsId);
        // console.log('| formDates: ' + formDates.dateStart + '/' + formDates.dateEnd);
        // console.log('| formFields: ' + fosrmFields);

        getSettings().then(null);
        updateResultTableFields();


        if (!formRestsId.length || (formDates.dateStart == '' || formDates.dateEnd == ''))
            setSubmitButtonDisabled(true);
        else
            setSubmitButtonDisabled(false);

        //раскидываем результаты в соотв. с полями
        if (in_array(tabValue, REPORT_TABS)) {

            if (tabValue === 'itogSmena') {

                if (reportResult.length) {
                    console.log('useEffect 4 itogSmena');
                    console.log('reportResult', reportResult.length, reportResultSecond.length);

                    getItogSmena(reportResult, reportResultSecond).then(null);
                }

            } else {
                if (!Sorter) {
                    (async () => {

                        const Sorter = await import('./report/sorter/' + tabValue);
                        // console.log('doSetTableResult in useEffect 1------');
                        doSetTableResult(Sorter);
                        // setResultTableData(Sorter.sort(
                        // 	filterData(reportResult),
                        // 	formFields,
                        // 	sortField
                        // ));
                    })();
                } else {
                    console.log('doSetTableResult in useEffect 2------');
                    doSetTableResult(Sorter);
                }
                // setResultTableData(Sorter.sort(
                // 	filterData(reportResult),
                // 	formFields,
                // 	sortField
                // ));
            }
        }

    }, [tabValue, formRestsId, formDates, formFields, filterSelected, sort]);


    let submitButtonDisabledDef = false;
    if (!formRestsId.length)
        submitButtonDisabledDef = true;
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(submitButtonDisabledDef);

    const [reportResult, setReportResult] = useState([]);
    //2ой отчет, исп. в ИТОГО ПО СМЕНЕ, доп запрос на кол-во чеков
    const [reportResultSecond, setReportResultSecond] = useState([]);

    const [isSubmitProcess, setIsSubmit] = useState(false);


    const setFilters = (reportId, data) => {
        console.log(`%c setFilters reportId=${reportId}`, 'color: green');
        // console.dir(data);
        let report = getReportById(reportId);

        let filters = {};
        let selected = {};

        if (report.filters !== undefined && report.filters.length > 0) {
            selected = {...filterSelected};
            report.filters.map((fld, i) => {
                if (fld in filters === false)
                    filters[fld] = [];

                if (fld in selected === false)
                    selected[fld] = [];

                data.map(item => {
                    if (fld in item !== false && !filters[fld].includes(item[fld]))
                        filters[fld].push(item[fld]);

                    if (!selected[fld].includes(item[fld]))
                        selected[fld].push(item[fld]);
                });

                filters[fld] = filters[fld].sort();
            });

        }
        // console.dir(filters);
        setTableFieldsFilters(filters);
        setFilterSelected(selected);

    }


    const getItogSmena = async (_itogSmenaData = null, _checksData = null) => {
        // console.clear();
        console.log('%c getItogSmena >>>>>', 'background: green');
        let itogSmenaData = _itogSmenaData
            ? _itogSmenaData
            : await handleSubmit({
                restsId: formRestsId,
                dateStart: formDates.dateStart,
                dateEnd: formDates.dateEnd,
                reportType: 'bydeposit'
            }, true);
        //
        // console.log('%c itogSmenaData >>>>>', 'background: #ccc');
        // console.dir(itogSmenaData);

        let checksData = _checksData
            ? _checksData
            : await handleSubmit({
                restsId: formRestsId,
                dateStart: formDates.dateStart,
                dateEnd: formDates.dateEnd,
                reportType: 'midCheque'
            }, true);
        // console.log('%c checksData >>>>>', 'background: #ccc');
        // console.dir(checksData);

        if (!Sorter)
            Sorter = await import('./report/sorter/itogSmena');


        if (Sorter) {
            // console.log('start smena sorter');
            // Sorter.sort(
            //     filterData(itogSmenaData),
            //     formFields,
            //     checksData
            // );

            console.log('doSetTableResult in getItogSmena');


            setResultTableData(Sorter.sort(
                filterData(itogSmenaData),
                formFields,
                checksData,
                sort,
                settings,
                restsList,
            ));
            if (_itogSmenaData === null) {
                setReportResult(itogSmenaData);
                setReportResultSecond(checksData);
            }
        } else
            alert('ОШИБКА: не загружен сортировщик');

    };


    var maxSteps = 3;
    let handleSubmitStep = 0;

    const handleSubmit = async (_sendData = null, returnData = false) => {
        console.clear();
        let result = null;
        if (handleSubmitStep >= maxSteps) {
            console.error('Первышено макс кол-во итераций');
            return result;
        }

        //тип отчета- параметр посылается на сервер
        let {reportType: _reportType = tabValue} = getReportById(tabValue);
        // REPORTS.map(rep => {
        //     if (rep.id == tabValue && typeof rep.reportType != 'undefined')
        //         _reportType = rep.reportType;
        // });

        console.log(_reportType);
        //при нажатии на кнопку сформировать отчет
        if (_reportType == 'bydeposit' && _sendData === null) {
            await getItogSmena();
            return;
        }


        const sendData = _sendData !== null
            ? _sendData
            : {
                restsId: formRestsId,
                dateStart: formDates.dateStart,
                dateEnd: formDates.dateEnd,
                reportType: _reportType
            };
        console.log('get data from server', typeof sendData);
        console.dir(sendData);


        setIsSubmit(true);


        try {

            handleSubmitStep++;
            const res = await Api.post('get_reports', sendData);
            // const res = await Ax.post('', sendData);


            setIsSubmit(false);

            if (res.data && res.data.ErrCode === 0) {// && res.data.report != undefined) {

                let report = res.data.report;
                if (res.data.reportbydays != undefined)
                    report = res.data.reportbydays;

                // Storage.set(cacheKey, report, (DEBUG ? 60 * 10 : 30));
                result = report;

                if (returnData)
                    return result;


                setReportResult(report);
                setFilters(tabValue, report);
                if (Sorter) {
                    // console.log('doSetTableResult in handleSubmit', 'returnData:', returnData);
                    doSetTableResult(Sorter, null);
                } else
                    alert('ОШИБКА: не загружен сортировщик');

            } else
                alert('Сервер ответил: ' + res.data.ErrMessage);
        } catch (err) {
            setIsSubmit(false);
            console.error(err);
            alert('Ошибка, см. консоль');

        }


    };

    //ВЫВОД

    let reportHeader = null;

    let reportResults = (
        <div className="">
            ...
        </div>
    );

    if (isSubmitProcess)
        reportResults = (
            <div className={classes.resultLoader}>
                <CircularProgress/>
            </div>
        );

    else if (resultTableData.length || Object.keys(resultTableData).length) {

        if (tabValue == 'itogSmena') {
            reportResults = <ItogSmena
                dates={formDates}
                tableFields={resultTableFields}
                formFields={formFields}
                data={resultTableData}
                reportType={tabValue}
                sort={sort}
                sortBy={sortBy}

            />
        } else {
            reportResults = (
                <>

                    <ReportResult
                        tableFields={resultTableFields}
                        formFields={formFields}
                        data={resultTableData}
                        reportType={tabValue}
                        sort={sort}
                        sortBy={sortBy}
                    />
                    <SaveToFile
                        tableFields={resultTableFields}
                        data={resultTableData}
                        dates={formDates}
                        reportId={tabValue}
                    />
                </>
            );
        }
    }
    let content = null;

    if (in_array(tabValue,['connections', 'settings'])) {
        if (tabValue === 'connections')
            content = <Connections restsList={restsList}/>;
        else if (tabValue === 'settings')
            content = <Settings restsList={restsList} settingsInit={settings}/>;
    }
    else {

        reportHeader = <ReportForm
            restsList={restsList}
            setFormRestsId={setFormRestsId}
            formDates={formDates}
            setFormDates={setFormDates}

            tableFields={tableFields}

            formFields={formFields}
            setFormFields={setFormFields}

            submitButtonDisabled={submitButtonDisabled}
            handleSubmit={handleSubmit}

            tableFieldsFilters={tableFieldsFilters}
            filterSelected={filterSelected}
            setFilterSelected={setFilterSelected}
        />;

        content = reportResults;
    }


    return (
        <React.Fragment>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabs}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {REPORTS.map((tab, i) => {
                            return (
                                <Tab
                                    key={tab.id}
                                    label={tab.title}
                                    value={tab.id}
                                    wrapped
                                    {...a11yProps(i)}

                                />
                            )
                        })}
                        {/*<Tab label="Отчеты 1" {...a11yProps(0)} />*/}
                        {/*<Tab label="Отчеты 2" {...a11yProps(1)} />*/}
                        {/*<Tab label="Подключения" value={"connections"} {...a11yProps(20)} />*/}
                    </Tabs>
                    <div className={classes.userInfo}>

                        <div className={classes.userName + " user-name"}>
                            {user ? user.name : 'NaN'}
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={logout}
                        >
                            Выйти
                        </Button>
                    </div>
                </Toolbar>

            </AppBar>
            <Container component="main" maxWidth="xl" className={classes.content}>
                <Header/>
                {reportHeader}
                {/*<TabPanel value={tabValue} index={0}>*/}
                {/*	<Report*/}
                {/*		*/}
                {/*	/>*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={tabValue} index={1}>*/}
                {/*	<Report*/}
                {/*	/>*/}
                {/*</TabPanel>*/}

                {/*{REPORTS.map((tab, i) => {*/}
                {/*	return (*/}
                {/*		<TabPanel value={tab.id} index={tab.i} key={tab.id}>*/}
                {/*			<ReportResult*/}
                {/*				tableFields={tab.table}*/}
                {/*				reportResult*/}
                {/*			/>*/}
                {/*		</TabPanel>*/}
                {/*	)*/}
                {/*})}*/}
                {/*<TabPanel value={"results"} index={0}>*/}
                {/*	результат*/}
                {/*</TabPanel>*/}
                {/*<TabPanel value={"connections"} index={20}>*/}
                {/*	подключения*/}
                {/*</TabPanel>*/}
                {content}

            </Container>
        </React.Fragment>
    );
};

export default Content;

