import React, {useEffect, useState} from 'react';
import {Grid, Checkbox, FormControlLabel} from "@material-ui/core";

const RestListItem = (props) => {

	const {data, onSelectRest} = props;
	// console.log('--render item--');
	// console.log(data);
	// console.log('data selected: ' + data.selected);
	let defSelected = data.online ? data.selected : false;
	// console.log('default Selected: ' + defSelected);
	// const [isSelected, setIsSelected] = useState(defSelected);

	// console.log('isSelected: ' + isSelected);


	// useEffect(() => {
	// 	console.log('useEffect data selected: ' + data.selected);
	// 	setIsSelected(data.online ? data.selected : false);
	//
	// }, [isSelected]);

	return(
		<Grid item xs={12} sm={6} lg={3}>
			<FormControlLabel
				control={<Checkbox
					id={data.id}
					color="primary"
					onChange={(e) => {
						// setSelfSelected(e.target.checked);
						onSelectRest(e);
					}}
					checked={defSelected}
					disabled={data.online ? false: true}
				/>}
				label={data.name}
			/>
		</Grid>
	);
};
export default RestListItem;