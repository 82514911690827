import axios from "axios";

export default axios.create({
    // eslint-disable-next-line no-undef
    baseURL: typeof API_URL != 'undefined' ? API_URL : '/',//process.env.REACT_APP_API_URL,
    // baseURL: "http://95.216.26.232:7698/",
    // baseURL: 'http://localhost/sklad/api/',
    // baseURL: 'http://31.148.136.10/sklad/api/',
    responseType: "json",
    timeout: 120000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
        // "Content-Type": "multipart/form-data"
        // "Access-Control-Allow-Origin": "*"
    }
})
