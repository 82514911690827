import React, {useState} from 'react';
import Api from "../Api";
import Container from "@material-ui/core/Container";
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCookies } from 'react-cookie';
import {COOKIE_NAME, COOKIE_MAX_AGE, DEBUG} from "../Constants";
import * as sha1 from "sha1";
import Storage from "../Storage";


const useStyles = makeStyles(theme => ({
	paper: {
		boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)',
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		padding: '1rem 0'
	},
	footer: {
		textAlign: 'center'
	}
}));

const Login = (props) => {
	const classes = useStyles();

	const [login, setLogin] = useState('');
	const [pass, setPass] = useState('');
	// const [isRemember, setRemember] = useState(true);
	const isRemember = true;
	const [actSubmit, setActSubmit] = useState(false);
	const {setUser} = props;

	const [cookies, setCookie] = useCookies([COOKIE_NAME]);

	const handleLogin = async (e) => {
		e.preventDefault();
		setActSubmit(true);
		const hashedPass = sha1(pass);
		const res = await Api.get('login.php', {
			params: {
				login: login,
				pwd: hashedPass
			}
		});
		//sha1
		// console.log(res);

		if (res.data) {
			setActSubmit(false);
			// console.log(res.data.user);
			if (res.data.ErrCode === 0 && res.data.user !== undefined) {
				Storage.clear();
				res.data.user.password = hashedPass;
				if (isRemember)
					setCookie(
						COOKIE_NAME, res.data.user, {
						path: '/',
						maxAge: COOKIE_MAX_AGE
					});
				setUser(res.data.user);
			}
			else
				alert(res.data.ErrMessage);

		}
		else {
			setActSubmit(false);
			alert('Не найден пользователь, либо сервер не отвечает');
		}

		// setTimeout(() => {
		//
		// 	const user = {
		// 			'name': 'Администратор',
		// 			'login': login,
		// 			'pass': sha1(pass)
		// 	};
		// 	if (isRemember)
		// 		setCookie(COOKIE_NAME, user, {
		// 			path: '/',
		// 			maxAge: COOKIE_MAX_AGE
		// 		});
		//
		// 	setUser(user);
		//
		// 	setActSubmit(false);
		// }, 500);

	};




	return(
		<Container component="main" maxWidth="xs" className={classes.paper}>
			<Typography component="h1" variant="h5">
				Вход
			</Typography>
			<form
				className={classes.form}
				onSubmit={handleLogin}
			>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="email"
					label="Логин"
					name="login"
					autoComplete="username"
					autoFocus
					defaultValue={login}
					onChange={(e) => setLogin(e.target.value.trim())}
				/>
				<TextField
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Пароль"
					type="password"
					id="password"
					autoComplete="current-password"
					defaultValue={pass}
					onChange={(e) => setPass(e.target.value.trim())}
				/>
				{/*<FormControlLabel*/}
				{/*	control={<Checkbox value="1" color="primary" onChange={(e) => setRemember(e.target.checked)}/>}*/}
				{/*	label="Запомнить меня"*/}
				{/*/>*/}
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
				>
					Войти
				</Button>
				<div className={classes.footer}>
					{actSubmit ? <CircularProgress /> : ""}
				</div>
			</form>
		</Container>
	)
}
export default Login;