import React, {useEffect, useState} from 'react';
import {Grid, Checkbox, FormControlLabel, List, ListItem, ListItemText, makeStyles, Badge, Popover} from "@material-ui/core";
import {array_unset} from "../../Constants";
// import Icons from '@material-ui/icons';
import FilterList from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
	item: {
		display: 'flex',
		alignItems: 'center'
	},
	filter: {
		position: 'relative',
		marginLeft: '10px'
	},
	filterList: {
		// position: 'absolute',
		// zIndex: 100,
		// background: '#fff',
		// border: '1px solid'
	}
}));

const FieldsItem = (props) => {
	const {
		name, title, checked = true,
		handleSelectedFields,
		filter = null, filterSelected = {}, setFilterSelected,
	} = props;

	const classes = useStyles();
	// console.log(props);

	const [selfSelected, setSelfSelected] = useState(checked);
	const [filterPopup, setFilterPopup] = useState(null);

	const showFilter = (e) => {
		setFilterPopup(e.currentTarget);
	};

	let filterHtml;
	// let filterBadgeId = `filter-badge-${id}`;
	// console.dir(filter);
	if (filter !== null) {
		filterHtml = (
			<div className={classes.filter}>
				<div
					className={classes.badge}
					onClick={showFilter}
				>
					<FilterList />
					{/*Фильтр*/}
				</div>
				{/*<Badge*/}
				{/*	// className={classes.badge}*/}
				{/*	// badgeContent={"3/5"}*/}
				{/*	color="primary"*/}
				{/*	// id={filterBadgeId}*/}
				{/*	onClick={showFilter}*/}
				{/*>*/}
				{/*	/!*<Icons.Filter />*!/*/}
				{/*	Фильтр*/}
				{/*</Badge>*/}
				<Popover
					// id={id}
					open={Boolean(filterPopup)}
					anchorEl={filterPopup}
					onClose={() => setFilterPopup(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<List className={classes.filterList}>
						{filter.map((filterItem, k) => {

							let _checked = checked;
							if (filterSelected[name] !== undefined
								&&
								!filterSelected[name].includes(filterItem)
							)
								_checked = false;

							return (
								<ListItem
									key={`filter-${name}-${k}`}
									button
									dense
									onClick={() => {
										let fs = {...filterSelected};
										if (name in fs === false)
											fs[name] = [];

										// console.log(fs[name]);

										if (!fs[name].includes(filterItem))
											fs[name].push(filterItem);
										else{
											// console.log('del', name, filterItem);
											fs[name] = array_unset(fs[name], filterItem);
										}
										// console.dir(fs);

										setFilterSelected(fs);
									}}
								>
									<Checkbox
										checked={_checked}
										tabIndex={-1}
										disableRipple
									/>
									<ListItemText id={filterItem} primary={filterItem}/>
								</ListItem>
							);
						})}
					</List>
				</Popover>
			</div>

		);

	} else
		filterHtml = null;
	// console.log(name, title, checked);

	return (
		<Grid item xs={12} sm={4} lg={4} className={classes.item}>
			<FormControlLabel
				control={<Checkbox
					value={name}
					color="primary"
					checked={selfSelected}
					onChange={(e) => {

						setSelfSelected(e.target.checked);
						handleSelectedFields(
							name,
							e.target.checked
						);
					}}
				/>}
				label={title}
			/>
			{filterHtml}
		</Grid>
	);
};

export default FieldsItem;
