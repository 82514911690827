import React from "react";
import {unixTime} from "./Constants";

const Storage = {
	/**
	 *
	 * @param key - ключ
	 * @param value - значение
	 * @param expire - время в секундах
	 */
	set: (key, value, expire = 0) => {
		// console.log('Storage set "' + key + '"; type "' + typeof value + '"; expires sec: ' + expire);
		localStorage.setItem(
			key,
			typeof value == 'object'  ? JSON.stringify(value) : value
		);

		if (expire)
			localStorage.setItem(key + '_exp', unixTime() + expire);

	},
	get: (key, defaultValue = null, isObject) => {
		let value = localStorage.getItem(key);// || defaultValue;
		if (value === null)
			return defaultValue;

		const expDate = localStorage.getItem(key + '_exp');

		// console.log(key +' exp time: ' + expDate);
		// console.log('now time: ' + unixTime());
		// console.log(expDate < unixTime());
		if (expDate && expDate < unixTime()) {
			localStorage.removeItem(key);
			localStorage.removeItem(key + '_exp');
			return defaultValue;
		}

		if (isObject)
			value = JSON.parse(value);

		return value;
	},
	clear: () => {
		localStorage.clear();
	}
};
export default Storage;