import React from 'react';
import {ExpandMore, ExpandLess, FileCopyRounded as Copy} from '@material-ui/icons';
// import {Grid, Checkbox, FormControlLabel} from "@material-ui/core";
import cn from 'classnames';
import {formatPrice, in_array, REPOST_SUMM_ROUND} from "../../Constants";


// const TableRow = (props) => {
// 	const {i, txt} = props;
// 	return (
// 		<td>
// 			{txt}
// 		</td>
// 	);
// };

const ReportResult = (props) => {
    const {
        tableFields,
        formFields,
        data = [],
        //сортировки
        sort, sortBy,
        showCounter = false
    } = props;


    // console.log('ReportResult......' + data.length);

    // console.log(data);

    if (!data.length)
        return '';

    let showTableFields = [];
    // let showTableFieldsNames = [];
    tableFields.map((field) => {
        if (field.dependFrom == undefined || in_array(field.dependFrom, formFields)) {
            showTableFields.push(field);
            // showTableFieldsNames.push(field.name);
        }
    });


    const tblRow = (item) => {
        return (
            <>
                {showTableFields.map((field, i) => {
                    let val = item[field.name] != undefined ? item[field.name] : '';
                    // console.log(val, typeof val);
                    if (typeof val == 'object' && val.length == 2) {
                        val = <>
                            <b>{val[0]}</b> {val[1]}
                        </>;
                    }
                    // val = val.replaceAll('[b]', '<b>');
                    // val = val.replaceAll('[/b]', '</b>');

                    // console.log(val, typeof val, field.hasOwnProperty('isPrice'));
                    if (field.hasOwnProperty('isPrice') && field.isPrice === true) {
                        val = formatPrice(val);
                    }
                    // if (typeof val == 'number') {
                    //
                    // }

                    return (
                        <td key={`res-row-td` + i} className={field.name + ' ' + item.css}>
                            {val}
                        </td>
                    );
                })}
            </>
        );
    };


    const sortArrows = (field) => {
        if (field.sortable == undefined || field.sortable === false)
            return null;

        // console.log(sort);
        return <div className={cn('sort', {active: sort.field == field.name})}>
            {
                sort.asc
                    ? <ExpandLess/>
                    : <ExpandMore/>
            }

        </div>
    }

    let tableRowCounter = 0;

    return (
        <div className="report-result ">
            <table className="table">
                <thead>
                <tr>
                    {
                        showCounter
                            ? <td className={'num'}>№</td>
                            : null
                    }
                    {showTableFields.map((field, i) => {
                        return (
                            <td
                                key={`tbl-h-td` + i}
                                onClick={() => {
                                    if (field.sortable == undefined || field.sortable === false)
                                        return;

                                    sortBy(field.name);
                                }}
                                className={field.name}
                            >
                                <div>
                                    <span>{field.title}</span>

                                    {sortArrows(field)}
                                </div>
                            </td>
                        );
                    })}
                </tr>
                </thead>
                <tbody>

                {
                    data.map((item, i) => {
                        let num = null;
                        if (showCounter) {
                            if (!in_array(item.css, ['itog', 'subitog'])) {
                                tableRowCounter++;
                                num = <td className={'num'}>{tableRowCounter}</td>;
                            }
                            else
                                num = <td className={item.css}>&nbsp;</td>;
                        }
                        return (
                            <tr key={'res-row' + i}>
                                {num}
                                {tblRow(item)}
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>


        </div>
    );
};

export default ReportResult;
